// @flow
// this is an auto generated file. This will be overwritten

export const createAccountGroup = /* GraphQL */ `
  mutation CreateAccountGroup(
    $input: CreateAccountGroupInput!
    $condition: ModelAccountGroupConditionInput
  ) {
    createAccountGroup(input: $input, condition: $condition) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountGroup = /* GraphQL */ `
  mutation UpdateAccountGroup(
    $input: UpdateAccountGroupInput!
    $condition: ModelAccountGroupConditionInput
  ) {
    updateAccountGroup(input: $input, condition: $condition) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountGroup = /* GraphQL */ `
  mutation DeleteAccountGroup(
    $input: DeleteAccountGroupInput!
    $condition: ModelAccountGroupConditionInput
  ) {
    deleteAccountGroup(input: $input, condition: $condition) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
