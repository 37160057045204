// @flow
// this is an auto generated file. This will be overwritten

export const onCreateAccountGroup = /* GraphQL */ `
  subscription OnCreateAccountGroup($owner: String!) {
    onCreateAccountGroup(owner: $owner) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccountGroup = /* GraphQL */ `
  subscription OnUpdateAccountGroup($owner: String!) {
    onUpdateAccountGroup(owner: $owner) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccountGroup = /* GraphQL */ `
  subscription OnDeleteAccountGroup($owner: String!) {
    onDeleteAccountGroup(owner: $owner) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount($owner: String!) {
    onCreateAccount(owner: $owner) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($owner: String!) {
    onUpdateAccount(owner: $owner) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount($owner: String!) {
    onDeleteAccount(owner: $owner) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction($owner: String!) {
    onCreateTransaction(owner: $owner) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction($owner: String!) {
    onUpdateTransaction(owner: $owner) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction($owner: String!) {
    onDeleteTransaction(owner: $owner) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
