// @flow

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export function ErrorDialog(props) {

    const onErrorDialogClose = () => {
        if (props.onClose){
            props.onClose();
        }
    }

    const actualError = props.error && props.error.errors ? props.error.errors[0] : props.error;

    return <Dialog onClose={onErrorDialogClose} open={props.error}>
        <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {actualError ? actualError.message : null}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onErrorDialogClose} color="primary" autoFocus>
                Back
            </Button>
        </DialogActions>
    </Dialog>
}