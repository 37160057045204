// @flex

import {AppBar, Divider, IconButton, Menu, MenuItem, Toolbar, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

type TopBarProps = {
    title: string,
    children: Object[]
}

function TopBar(props: TopBarProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div>
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {props.title}
                </Typography>
                {props.children}
            </Toolbar>
        </AppBar>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem component={Link} to={"/transactions"} onClick={handleClose}>Latest transactions</MenuItem>
            <MenuItem component={Link} to={"/expenseAccounts"} onClick={handleClose}>Manage Expense Accounts</MenuItem>
            <MenuItem component={Link} to={"/assetAccounts"} onClick={handleClose}>Manage Asset Accounts</MenuItem>
            <Divider />
            <MenuItem component={Link} to={"/signout"} onClick={handleClose}>Sign out</MenuItem>
        </Menu>
    </div>
}

export default TopBar;
