// @flow

import {Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {withQuery} from "../common/withQuery";
import AccountDetailDialog from "./AccountDetailDialog";
import {Fragment, useState} from "react";
import type {AccountType} from "../API";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import {
    onCreateAccount,
    onCreateAccountGroup, onDeleteAccount,
    onDeleteAccountGroup, onUpdateAccount,
    onUpdateAccountGroup
} from "../graphql/subscriptions";
import TopBar from "../TopBar";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

type AccountListProps = {
    type: AccountType,
}

function AccountList(props: AccountListProps) {
    const classes = useStyles();

    // group for which we have the detail dialog open (if any).
    const [openGroupDetail, setOpenGroupDetail] = useState();

    // Id of the group which is currently unfolded.
    const [unfoldedGroupId, setUnfoldedGroupId] = useState();

    function newGroup() {
        setOpenGroupDetail({
            name: '',
            description: '',
            type: props.type,
        });
    }

    function editGroup(group) {
        setOpenGroupDetail(group);
    }

    function newAccount(groupId: string) {
        setOpenGroupDetail({
            name: '',
            description: '',
            type: props.type,
            groupId: groupId,
        });
    }

    function editAccount(account) {
        setOpenGroupDetail(account);
    }

    function foldUnfold(groupId) {
        if (unfoldedGroupId === groupId) {
            setUnfoldedGroupId(null)
        } else {
            setUnfoldedGroupId(groupId)
        }
    }

    function renderAccount(account) {
        return <ListItem key={account.id} button className={classes.nested}>
            <ListItemText primary={account.name} secondary={account.description}/>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => editAccount(account)}>
                    <EditIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    }

    function renderGroup(group) {
        const unfolded = unfoldedGroupId === group.id
        return <Fragment>
            <ListItem key={group.id} button onClick={() => foldUnfold(group.id)}>
                <ListItemText key={group.id} primary={group.name} secondary={group.description}/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit" onClick={() => editGroup(group)}>
                        <EditIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse key={group.id + "collapse"} in={unfolded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {group.accounts.items.map((account) => renderAccount(account))}
                    <ListItem key={group.id + "new"} button className={classes.nested}
                              onClick={() => newAccount(group.id)}>
                        <ListItemText primary="Add new Account..."/>
                    </ListItem>
                </List>
            </Collapse>
        </Fragment>;
    }

    return <div>
        <TopBar title={(props.type === "EXPENSE" ? "Expense" : "Asset") + " accounts"} />
        <List>
            {props.value.listAccountGroups.items.map(group => renderGroup(group))}
            <ListItem key="new group" button onClick={newGroup}>
                <ListItemText primary="Add New Group..."/>
            </ListItem>
        </List>
        {openGroupDetail != null ? <AccountDetailDialog open={true} account={openGroupDetail}
                                                        onClose={() => setOpenGroupDetail(null)}/> : null}
    </div>
}

export const listAccountGroups = /* GraphQL */ `
  query ListAccountGroups(
    $filter: ModelAccountGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        accounts {
          items{
            id
            type
            name
            description
            groupId
          }
        }
      }
    }
  }
`;

const AccountListWithQuery = withQuery(listAccountGroups, [
    onCreateAccountGroup,
    onUpdateAccountGroup,
    onDeleteAccountGroup,
    onCreateAccount,
    onUpdateAccount,
    onDeleteAccount])(AccountList)

function AccountListFinal(props) {
    const {type} = props;

    return <AccountListWithQuery type={type} queryInput={{
        filter: {
            type: {
                eq: type,
            }
        }
    }} {...props} />
}

export default AccountListFinal;