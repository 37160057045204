import {Async} from "react-async";
import {useState, Fragment} from "react";
import {ErrorDialog} from "./ErrorDialog";
import {Button} from "@material-ui/core";

export const withAsyncOperation = (Component) => (props) => {
    const {onClick, showDialog, disabled, ...otherProps} = props;

    const [promise, setPromise] = useState();

    const onButtonClick = (e) => {
        const p = onClick(e);
        setPromise(p)
    }

    return <Async promise={promise}>
        {({ data, error, isPending }) => {
            return <Fragment>
                <Component onClick={onButtonClick} disabled={disabled || isPending} {...otherProps} />
                {showDialog ? <ErrorDialog error={error} title={"Operation failed"} /> : null}
            </Fragment>
        }}
    </Async>
}

export const AsyncButton = withAsyncOperation(Button);

