// @flow
// this is an auto generated file. This will be overwritten

export const getAccountGroup = /* GraphQL */ `
  query GetAccountGroup($id: ID!) {
    getAccountGroup(id: $id) {
      id
      type
      name
      description
      deprecated
      accounts {
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listAccountGroups = /* GraphQL */ `
  query ListAccountGroups(
    $filter: ModelAccountGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      type
      name
      description
      groupId
      group {
        id
        type
        name
        description
        deprecated
        owner
        createdAt
        updatedAt
      }
      debitTransactions {
        nextToken
      }
      creditTransactions {
        nextToken
      }
      deprecated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      debitAccountId
      debitAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      creditAccountId
      creditAccount {
        id
        type
        name
        description
        groupId
        deprecated
        owner
        createdAt
        updatedAt
      }
      date
      amount
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        debitAccountId
        creditAccountId
        date
        amount
        description
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionByOwnerAndDate = /* GraphQL */ `
  query TransactionByOwnerAndDate(
    $owner: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TransactionByOwnerAndDate(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitAccountId
        creditAccountId
        date
        amount
        description
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
