import {useEffect, useReducer, useState} from "react";

/**
 * Returns a function that in turn returns a boolean indicating if the component is still mounted.
 */
function useMountCheck() {
    const [state] = useState({mounted:true});
    useEffect(() => () => {
        state.mounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return function isMounted() {
        return state.mounted;
    }
}


/**
 * Wraps the given function in another function that can safely be executed from outside the component.
 * The given function will always be executed within the component with the correct, current, closure.
 * If the returned function is invoked, the function passed to `useAsyncCallback()` is invoked, however not immediately.
 * Return values are not passed on.
 * Once the component is unmounted, calls are ignored.
 */
export function useAsyncCallback(fn){
    const isMounted = useMountCheck();

    function reducer(pendingActions: any[], action) {
        if (pendingActions.length === 0) {
            // No action pending. Create new array. This changes the identify of the array, which react
            // will detect as a change of state, making it re-render.
            return [action];
        }else {
            // Already have an action pending, which means we changed the identify of the state before, so
            // react is already going to re-render the component. We can keep using the same array.
            pendingActions.push(action);
            return pendingActions;
        }
    }

    const [pendingActions: any[], dispatch] = useReducer(reducer, []);

    /**
     * Asynchronously invokes the wrapped function.
     */
    function invoker() {
        if (isMounted()){
            dispatch(arguments);
        }
    }

    // We are running in the render function, which must remain pure.
    // By invoking the actions from an effect, we can bypass that limitation.
    useEffect(() => {

        // Executing the pending actions may cause new actions to be added. To avoid concurrent modification
        // issues, we clone the actions.
        const pendingActionClone = [...pendingActions];

        // We empty the pending actions but keep using the same array instance.
        // React won't detect this as a state change, avoiding a re-render.
        pendingActions.length = 0;

        // Execute all pending actions.
        pendingActionClone.forEach(args => fn(...args));
    });

    return invoker;
}


/**
 * Provides the status of a promise.
 *
 * The first three return values describe the status of the promise:
 * * `value`: value of the current promise or `undefined` if there is no current promise, it is still pending, or resulted in an error.
 * * `error`: error of the current promise or `undefined` if there is no current promise, it is still pending, or resulted in a value.
 * * `isPending`: `true` if the promise has not yet concluded. `false` otherwise (including if there is no current promise).
 *
 * The last is a function that can be used to change the current promise.
 * @param then Optional callback that is invoked when the promise concludes. It is given `value` and `error`.
 *  Invoked within the context of the component, and only if the component has not been unmounted.
 * @return {[any, any, boolean, setPromise]} `[value, error, isPending, setPromise]`
 */
export function usePromiseState(then: ?(any, any) => void): [any, any, boolean, Promise => void] {

    /// `componentState` is never replaced. We change data inside it, but keep the same object reference.
    /// Never triggers a re-render since we never replace the object.
    const [componentState] = useState({
        /// Currently set promise. We reset this to `null` if the component unmounts.
        currentPromise: null,
        /// Optional cancel function for the current promise.
        currentCancelFc: null,
    });

    const [state, setState] = useState({
        value: undefined,
        error: undefined,
        pending:false,
        invokeThen:false})

    /**
     * Changes the promise which state is tracked.
     * @param promise Promise to track. To stop tracking a promise, set to null.
     * @param cancel Function invoked when the component is unmounted while the promise is still pending, or
     *  if `setPromise()` is called again with another promise while the current one is pending. Receives
     *  the promise to be cancelled as the argument. Optional.
     */
    function setPromise(promise: Promise, cancel: ?(promise: Promise) => void) {
        // Avoid chaining ourselves to the same promise over and over again.
        if (componentState.currentPromise === promise) return;

        // If we are replacing a promise, cancel it first.
        if (componentState.currentCancelFc != null) {
            componentState.currentCancelFc(componentState.currentPromise);
        }

        componentState.currentPromise = promise
        componentState.currentCancelFc = cancel
        setState({
            value: undefined,
            error: undefined,
            pending:false,
            invokeThen:false});

        if (promise == null) return;

        promise.then(value => {
            // If we got unmounted or promise got replaced, ignore result.
            if (componentState.currentPromise === promise){
                setState({
                    value: value,
                    error: undefined,
                    pending:false,
                    invokeThen:true});
                componentState.currentPromise = null;
                componentState.currentCancelFc = null;
            }

        }, error=>{
            // If we got unmounted or promise got replaced, ignore result.
            if (componentState.currentPromise === promise) {
                setState({
                    value: undefined,
                    error: error,
                    pending: false,
                    invokeThen: true
                });
                componentState.currentPromise = null;
                componentState.currentCancelFc = null;
            }
        });
    }

    // Effect to detect when the component unmounts.
    // Used for the optional cancel and to avoid setting the state of an unmounted component, which
    // would cause a warning to be logged.
    useEffect(()=> () => {
        // Component unmounted. Cancel if possible.
        if (componentState.currentCancelFc != null) {
            componentState.currentCancelFc(componentState.currentPromise);
        }
        componentState.currentPromise = null;
        componentState.currentCancelFc = null;
    }, [componentState]); // dependency is just to keep linter happy. componentState never changes.

    // Invoke callback from within an effect. This allows the implementation of `then` to perform status
    // changes, which are not allowed within render functions directly as they have to be pure.
    useEffect(() => {
        if (then != null && state.invokeThen){
            if (state.pending) throw new Error("Unexpected state");
            // Avoid re-render by changing the flag inside the existing object. React won't notice the change,
            // but we will avoid invoking `then` a second time.
            state.invokeThen = false;
            then(state.value, state.error);
        }
    });

    return [state.value, state.error, state.pending, setPromise];
}

/**
 * Calls the async function `func` with the given arguments.
 * If the arguments change, the old call is cancelled (if supported) and a new call is made.
 * @param func Function to call. If the function object has a `cancel` property, it will be invoked with the
 *   promise as the sole argument.
 * @param args Arguments passed on to `func`.
 * @return [any, any, boolean] Returns value, error, and isPending.
 */
export function useAsyncCall(func: () => void, ...args: any[]){
    const [value, error, pending, setPromise] = usePromiseState();
    useEffect(() => {
        const promise = (async () => await func(...args))();
        setPromise(promise, (p) => {
            if (func.cancel) func.cancel(p);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...args]);

    if (value === undefined && error === undefined && !pending){
        // We don't have a promise active, nor did the previous promise conclude.
        // This happens temporarily before the effect callback is invoked. Reporting this
        // as pending, since the call hasn't even started yet.
        return [value, error, true];
    }
    return [value, error, pending];
}

/**
 * Similar to useState(), but the setter accepts both a value or a promise.
 * If a promise is set, the state retains the current value until the promise fulfills.
 *
 * @param initialValue  Initial value. If an initial promise is provided as well, this state is used
 *  until the initial promise is fulfilled.
 * @param initialPromise Promise we are initially waiting for.
 */
export function useAsyncState(initialValue?: any, initialPromise?: Promise){
    if (initialValue instanceof Promise) throw new Error("initialState cannot be a promise. Use initialPromise instead");
    const [internalState] = useState({
        value: initialValue,
        reason: undefined,
        promise: initialPromise,
        pending: false,
    });

    const [, rerender] = useReducer(count => (count + 1) % (Number.MAX_SAFE_INTEGER - 1), 0);

    function setState(value: any){
        if (value instanceof Promise){
            const promise = value;
            if (promise === internalState.promise && internalState.pending) return;
            internalState.promise = promise;
            internalState.pending = true;
            rerender();
            promise.then(value => {
                if (internalState.promise !== promise) return;
                internalState.value = value;
                internalState.reason = null;
                internalState.promise = null;
                internalState.pending = false;
                rerender();
            }, reason => {
                if (internalState.promise !== promise) return;
                internalState.value = null;
                internalState.reason = reason;
                internalState.promise = null;
                internalState.pending = false;
                rerender();
            });
        }else{
            if (internalState.value === value &&
                internalState.reason === null &&
                internalState.promise === null &&
                !internalState.pending){
                return;
            }
            internalState.value = value;
            internalState.reason = null;
            internalState.promise = null;
            internalState.pending = false;
            rerender();
        }
    }

    // Cannot hookup the then() handlers to the initial promise directly within useAsyncState(),
    // as the handlers might be invoked synchronously, and that would make the render function non-pure.
    useEffect(() => {
        if (internalState.promise != null && !internalState.pending){
            setState(internalState.promise);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [internalState.value, internalState.reason, internalState.promise != null, setState]
}
