import TopBar from "../TopBar";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function SignOut(props) {
    const classes = useStyles();
    const history = useHistory();

    function onClick() {
        Auth.signOut();
        history.push('/');
    }

    return <div>
        <TopBar title={"Sign out"} />
        <div>
            <Button variant="contained" onClick={onClick}>Sign out?</Button>
        </div>
    </div>
}