// @flow

import {Backdrop, Button, CircularProgress, Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import AccountSelect from "../account/AccountSelect";
import {makeStyles} from "@material-ui/core/styles";
import {API, graphqlOperation} from "aws-amplify";
import {Transaction} from "../model";
import {createTransaction, updateTransaction} from "../graphql/mutations";
import {useAsyncCallback, useAsyncState, usePromiseState} from "../common/asyncHooks";
import {DateTime} from "luxon";
import {query} from "../common/graphqlHooks";
import {getTransaction} from "../graphql/queries";
import {ErrorDialog} from "../common/ErrorDialog";
import {useHistory, useParams} from "react-router-dom";
import TopBar from "../TopBar";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(2),
    },
    buttonGroup: {
        textAlign: "right",
    },

}));

async function create(transaction: Transaction) {
    await API.graphql(graphqlOperation(createTransaction, {input: transaction}));
}

async function update(transaction: Transaction) {
    await API.graphql(graphqlOperation(updateTransaction, {input: transaction}));
}

type TransactionDetailProps = {
}
function TransactionDetail(props: TransactionDetailProps){
    const classes = useStyles();
    const params = useParams();
    const history = useHistory();

    const emptyAccount = {
        date: DateTime.now(),
        creditAccount: null,
        debitAccount: null,
        amount: '',
        description: '',
    };
    const [transaction, error, pending, setTransaction] = useAsyncState(emptyAccount, (async () => {
        if (!params.transactionId) return emptyAccount;
        const data = await query(getTransaction, {id: params.transactionId});
        const account = data.getTransaction;
        account.date = DateTime.fromISO(account.date).toLocal();
        return account;
    })());

    const [, saveError, saveInProgress, setSavePromise] = useAsyncState();
    const onSaveComplete = useAsyncCallback(() => {
        if (!transaction.id){
            setTransaction({...transaction, amount: '', description: ''});
        }else{
            history.goBack();
        }
    })

    if (pending) {
        return <Backdrop open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    } else if (error) {
        return <ErrorDialog title="Error loading transaction" error={error} />
    }

    const date = transaction.date;
    const credit = transaction.creditAccount;
    const debit = transaction.debitAccount;
    const amount = transaction.amount;
    const description = transaction.description;

    const amountNumber = Number(amount);
    const completelyFilledOut = date != null && credit != null && debit != null && !isNaN(amountNumber) && amountNumber !== 0;

    function onSave() {
        const parameters: Transaction = {
            debitAccountId: debit.id,
            creditAccountId: credit.id,
            date: date.toUTC().toISO(),
            description: description,
            amount: amountNumber,
        }

        let promise;
        if (transaction.id){
            parameters.id = transaction.id;
            promise = update(parameters);
        }else{
            promise = create(parameters);
        }
        promise = promise.then(onSaveComplete);
        setSavePromise(promise);
    }

    function onClose() {
        history.goBack();
    }

    return <div>
        <TopBar title={"Transactions"}/>
        <div className={classes.content}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <KeyboardDateTimePicker fullWidth label={"Date"} autoOk value={date}
                                            onChange={d => setTransaction({...transaction, date: d})}
                                            format="yyyy/MM/dd mm:ss"/>
                </Grid>
                <Grid item xs={12}>
                    <AccountSelect account={credit} setAccount={a => setTransaction({...transaction, creditAccount: a})} type={"EXPENSE"}/>
                </Grid>
                <Grid item xs={12}>
                    <AccountSelect account={debit} setAccount={a => setTransaction({...transaction, debitAccount: a})} type={"ASSET"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        fullWidth
                        value={description}
                        onChange={e => setTransaction({...transaction, description: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Amount"
                        id="standard-start-adornment"
                        type="number"
                        value={amount}
                        onChange={e => setTransaction({...transaction, amount: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonGroup}>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button showDialog color="primary" disabled={!completelyFilledOut || saveInProgress} onClick={onSave}>
                            {saveInProgress ? "Saving..." : "Save"}
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} hidden={saveError == null} className={classes.buttonGroup}>
                    <Typography color="error">An error occurred: {JSON.stringify(saveError)}</Typography>
                </Grid>
            </Grid>
        </div>
    </div>
}

export default TransactionDetail;