import {Async} from "react-async";
import {makeStyles} from "@material-ui/core/styles";
import {ErrorDialog} from "./ErrorDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        left:0,
        right:0,
        top:0,
        bottom:0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.5,
        color: '#FFF',
        backgroundColor: 'black',
    }
}));

function AsyncLoader(props) {
    const classes = useStyles();
    const {initialData, onError, ...otherProps} = props;

    return <Async {...otherProps}>
        {({ data, error, isPending }) => {
            return <div className={classes.root}>
                <div>
                    {props.children(isPending ? props.initialData : data)}
                </div>
                <div className={classes.overlay} style={{display:isPending ? 'flex': 'none'}}>
                    <div>
                        Loading...
                    </div>
                </div>
                <ErrorDialog error={error} title={"Error while loading data"} />
            </div>
        }}
    </Async>
}

export default AsyncLoader;