/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8a783343-9904-472c-8044-dd9272a52cb2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ORoi3wfxu",
    "aws_user_pools_web_client_id": "64fiktiief2qo5lkqkr9i8p0jc",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wxr7ocgnfzallpekbyy6l4xpke.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
