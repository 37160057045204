// @flow

import React, {useEffect, useState} from 'react'
import {API, graphqlOperation, Auth} from "aws-amplify";
import {Backdrop, CircularProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {ErrorDialog} from "./ErrorDialog";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const INIT = "INIT";
const ERROR = "ERROR";
const LOADED = "LOADED";

type AllState = {
    state: INIT | ERROR | LOADED,
    value: any,
};

type withQueryProps = {
    // Arguments passed to the graphql query.
    queryInput: Object,

    // Optional. If provided, the wrapped component is displayed before data is loaded.
    // Otherwise a spinner covers the page.
    initialValue: ?any,

    // Invoked when an error occurs during loading or if the loading process is aborted and the user
    // confirmed the error dialog.
    // If no onClose handler is provided, we don't show an error dialog, instead the error is passed into
    // the component as `value` and `error` is set to true.
    onClose: ?() => void,
};


export const withQuery = (query: string, subscriptions: string[]) => (Component: any) => React.forwardRef((props: withQueryProps, ref) => {
    const {queryInput, initialValue, ...otherProps} = props;
    const classes = useStyles();

    const [allState: AllState, setAllState] = useState({state:INIT, value:initialValue})

    useEffect(() => {

        let queryOngoing = false;
        let pendingQuery = false;

        function startQuery() {
            // Don't perform multiple queries in parallel. But mark that another one has been requested.
            if (queryOngoing){
                pendingQuery = true;
                return;
            }

            // Query
            const promise = API.graphql(graphqlOperation(query, queryInput));
            promise.then(
                result => {
                    setAllState({
                        state:LOADED,
                        value:result.data,
                    })
                    queryOngoing = false;
                    if (pendingQuery){
                        startQuery();
                    }
                },
                error => {
                    setAllState({
                        state:ERROR,
                        value:error,
                    })
                    queryOngoing = false;
                }
            )
        }

        let subscriptionHandles = [];
        let unmounted = false;

        function startSubscriptions() {
            Auth.currentAuthenticatedUser().then(user => {
                if (!unmounted){
                    subscriptionHandles = subscriptions.map(s => {
                        return API.graphql(graphqlOperation(s, {owner: user.username})).subscribe({
                            next: () => {
                                startQuery();
                            },
                            error: error => {
                                setAllState({
                                    state:ERROR,
                                    value:error.error,
                                })
                            }
                        });
                    });

                }
            });

        }

        startSubscriptions();
        startQuery();

        return () => {
            subscriptionHandles.forEach(s => s.unsubscribe());
            unmounted = true;
        };
    }, [queryInput])

    if (allState.state === INIT && allState.value == null) {
        return <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    }else if (allState.state === ERROR && props.onClose) {
        return <ErrorDialog error={allState.value} onClose={props.onClose} />
    }else{
        return <Component ref={ref} value={allState.value} error={allState.state === ERROR} {...otherProps} />;
    }
});