// @flow

import {TextField, Grid, Button} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {useState} from "react";
import {API, graphqlOperation} from 'aws-amplify';
import TopBar from "../TopBar";
import AsyncLoader from "../common/AsyncLoader";
import {AsyncButton} from "../common/AsyncButton";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    }

}));

const getAccount = /* GraphQL */ `
      query GetAccount($id: ID!) {
        getAccount(id: $id) {
          id
          type
          name
          description
          groupId
        }
      }
    `;

const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
    }
  }
`;

const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
    }
  }
`;

function AccountInner(props) {
    const classes = useStyles();
    const [data, setData] = useState(props.data);

    const onNameChange = (e) => {
        setData(Object.assign({}, data, {name: e.target.value}))
    }

    const onDescriptionChange = (e) => {
        setData(Object.assign({}, data, {description: e.target.value}))
    }

    const onSave = async () => {
        const dataToSave = {
            id: data.id,
            name: data.name,
            description: data.description,
            groupId: data.groupId,
            type: data.type,
        }
        if (data.id){
            return API.graphql(graphqlOperation(updateAccount, {input: dataToSave}))
        }else{
            return API.graphql(graphqlOperation(createAccount, {input: dataToSave}))
        }
    }

    const accountTypeName = new Map();
    accountTypeName.set("EXPENSE", "Expense");
    accountTypeName.set("ASSET", "Assert");

    return <div>
        <TopBar title={!data.id ? "New " + accountTypeName.get(data.type) + " Account" : data.name} />
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField label="Name" fullWidth value={data.name} onChange={onNameChange}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Description" fullWidth value={data.description} onChange={onDescriptionChange}/>
                </Grid>
                <Grid item xs={6}>
                    <Button className={classes.button} variant="contained" onClick={props.onClose}>close</Button>
                </Grid>
                <Grid item xs={6}>
                    <AsyncButton className={classes.button} variant="contained" onClick={onSave}>save</AsyncButton>
                </Grid>
            </Grid>
        </div>
    </div>
}

function Account(props) {
    const initialData = {
        name: '',
        description: '',
        groupId: props.groupId,
        type: props.type,
    }

    const fetchData = async (id) => {
        if (!props.id) {
            return initialData;
        } else {
            const result = await API.graphql(graphqlOperation(getAccount, {id: id}));
            return result.data.getCategory;
        }
    };

    return <AsyncLoader initialData={initialData} promiseFn={fetchData} id={props.id}>
        {data => <AccountInner data={data}/>}
    </AsyncLoader>
}

export default Account