// @flow

import {API, graphqlOperation} from "aws-amplify";
import {useAsyncCall} from "./asyncHooks";
import {useCallback, useReducer} from "react";

/// Convert GraphQLResult with errors to a single error object.
function convertError(result) {
    if (result.errors.length === 0) throw new Error("Result not an error");
    return new Error(result.errors.map(e => e.message).join(', '));
}


async function doQuery(queryFunc, reloadToggle, ...args){
    const query = await queryFunc(...args);
    if (!query) return null;
    try{
        const result = await API.graphql(query);
        return result.data;
    }catch(result){
        throw convertError(result);
    }
}

export function useAsyncQuery(queryFunc, ...args) {
    const [reloadToggle, dispatch] = useReducer((toggle) => !toggle, false);
    function reload() {
        dispatch(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedQueryFunc = useCallback(queryFunc, [reloadToggle, ...args]);
    const [value, error, pending] = useAsyncCall(doQuery, memoizedQueryFunc, reloadToggle, ...args);
    return [value, error, pending, reload];
}

export async function query(query, parameters){
    try{
        const result = await API.graphql(graphqlOperation(query, parameters));
        return result.data;
    }catch(result){
        throw convertError(result);
    }
}
