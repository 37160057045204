// @flow

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import type {AccountType} from "../API";
import {useState} from "react";
import {AsyncButton} from "../common/AsyncButton";
import {API, graphqlOperation} from "aws-amplify";
import {ErrorDialog} from "../common/ErrorDialog";

function validateName(name: string, complainIfEmpty: boolean): ?string {
    if (name.length > 50) return "Name too long";
    if (complainIfEmpty && name.length === 0) return "Name cannot be empty";
    return null;
}

function validateDescription(name: string, complainIfEmpty: boolean): ?string {
    if (name.length > 200) return "Description too long";
    return null;
}

export const createAccountGroup = /* GraphQL */ `
  mutation CreateAccountGroup(
    $input: CreateAccountGroupInput!
    $condition: ModelAccountGroupConditionInput
  ) {
    createAccountGroup(input: $input, condition: $condition) {
      id
      type
      name
      description
      owner
    }
  }
`;
export const updateAccountGroup = /* GraphQL */ `
  mutation UpdateAccountGroup(
    $input: UpdateAccountGroupInput!
    $condition: ModelAccountGroupConditionInput
  ) {
    updateAccountGroup(input: $input, condition: $condition) {
      id
      type
      name
      description
      owner
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
      owner
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      type
      name
      description
      groupId
      owner
    }
  }
`;

type Account = {
    // If the `id` is not set, the dialog creates a new account group.
    id: ?string,
    name: string,
    description: string,
    type: AccountType,
    groupId: string,
}

function createOrUpdateAccountGroup(account : Account) {
    let mutation;
    if (!account.groupId){
        if (account.id) {
            mutation = updateAccountGroup;
        }else{
            mutation = createAccountGroup;
        }
    }else{
        if (account.id) {
            mutation = updateAccount;
        }else{
            mutation = createAccount;
        }
    }
    return API.graphql(graphqlOperation(mutation, {input: account}))
}

type AccountDetailDialogProps = {
    open: boolean,
    onClose: ?any,
    account: Account,
}

function AccountDetailDialog(props: AccountDetailDialogProps) {

    const [account: Account, setAccountGroup] = useState({
        id: props.account.id,
        name: props.account.name,
        description: props.account.description,
        type: props.account.type,
        groupId: props.account.groupId,
    });
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [saveError, setSaveError] = useState();
    const [saveSuccessful, setSaveSuccessful] = useState(false);

    if (saveSuccessful){
        if (props.onClose) props.onClose();
        setSaveSuccessful(false);
    }

    const onSave = async () => {
        setSaveAttempted(true);
        if (validateName(account.name, true) ||
            validateDescription(account.description, true)) {
            return;
        }
        try {
            await createOrUpdateAccountGroup(account);
            setSaveSuccessful(true);
        }catch (e) {
            setSaveError(e);
        }
    }

    if (saveError){
        return <ErrorDialog onClose={props.onClose} error={saveError} title={"Saving account group failed"} />
    }

    const nameError = validateName(account.name, saveAttempted);
    const descriptionError = validateDescription(account.description, saveAttempted);

    return <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            {(() => {
                if (!props.account.groupId) {
                    if (account.id) {
                        return "Change account group";
                    }else{
                        return "Create new account group"
                    }
                } else {
                    if (account.id) {
                        return "Change account";
                    }else{
                        return "Create new account"
                    }
                }
            })()}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {(() => {
                    if (!props.account.groupId) {
                        return "Account groups help you organize your accounts and give you summarized balances.";
                    }else{
                        return "Accounts summarize transactions placed with them."
                    }
                })()}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                error={nameError}
                helperText={nameError}
                value={account.name}
                onChange={(e) => setAccountGroup(Object.assign({}, account, {name:e.target.value}))}
            />
            <TextField
                margin="dense"
                label="Description"
                fullWidth
                error={descriptionError}
                helperText={descriptionError}
                value={account.description}
                onChange={(e) => setAccountGroup(Object.assign({}, account, {description:e.target.value}))}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Cancel
            </Button>
            <AsyncButton onClick={onSave} color="primary">
                Save
            </AsyncButton>
        </DialogActions>
    </Dialog>
}

export default AccountDetailDialog