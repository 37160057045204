// @flow

import {
    Button, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle,
    FormLabel, List, ListItem, ListItemText,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {withQuery} from "../common/withQuery";
import {useState, Fragment} from "react";
import type {Account} from "../model";
import {ModelAccountGroupFilterInput} from "../API";
import type {AccountType} from "../API";

export const listAccountGroups = /* GraphQL */ `
  query ListAccountGroups(
    $filter: ModelAccountGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        accounts {
          items{
            id
            type
            name
            description
            groupId
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    shrink: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: `top left`,
        display: "block",
    },
    button: {
        width: "100%",
    },
    buttonRoot: {
        textTransform: "none",
    },
    buttonLabel: {
        display: "block",
        textAlign: "left",

    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

type AccountListProps = {
    selected: Account,
    onClick: (Account) => void,
    queryInput: {filter: ModelAccountGroupFilterInput},
};

const AccountList = withQuery(listAccountGroups, [])((props: AccountListProps) => {

    // Id of the group which is currently unfolded.
    const [unfoldedGroupId, setUnfoldedGroupId] = useState(props.selected ? props.selected.groupId : null);

    const classes = useStyles();

    function foldUnfold(groupId) {
        if (unfoldedGroupId === groupId) {
            setUnfoldedGroupId(null)
        } else {
            setUnfoldedGroupId(groupId)
        }
    }

    function renderAccount(account) {
        const selected = props.selected ? account.id === props.selected.id : false;
        return <ListItem key={account.id} button className={classes.nested} onClick={() => props.onClick(account)} selected={selected}>
            <ListItemText primary={account.name} secondary={account.description} />
        </ListItem>
    }

    function renderGroup(group) {
        const unfolded = unfoldedGroupId === group.id
        return <Fragment>
            <ListItem key={group.id} button onClick={() => foldUnfold(group.id)}>
                <ListItemText key={group.id} primary={group.name} secondary={group.description}/>
            </ListItem>
            <Collapse key={group.id + "collapse"} in={unfolded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {group.accounts.items.map((account) => renderAccount(account))}
                </List>
            </Collapse>
        </Fragment>;
    }

    return <List>
        {props.value.listAccountGroups.items.map(group => renderGroup(group))}
    </List>

});

function AccountListDialog(props) {
    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{props.description}</DialogContentText>
            <AccountList {...props} />
        </DialogContent>
    </Dialog>
}

type AccountSelectProps = {
    type: AccountType,
    account: Account,
    setAccount: (Account) => void,
};

function AccountSelect(props: AccountSelectProps) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    function onExpenseAccountSelect(account: Account){
        props.setAccount(account);
        setDialogOpen(false);
    }

    function onClose(){
        setDialogOpen(false);
    }

    const title = props.type === "EXPENSE" ? "Select Expense Account" : "Select Asset Account";
    const description = props.type === "EXPENSE" ?
        "Transaction will book the funds to this account." :
        "Transaction will remove the funds from this account.";

    return <div>
        <FormLabel className={classes.shrink}>Expense Account</FormLabel>
        <Button className={classes.button} classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
        }} variant="outlined" onClick={() => setDialogOpen(true)}>
            <Typography
                variant={'body1'}
                component="span"
                display="block">
                {props.account ? props.account.name : "Select..."}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                display="block">
                {props.account ? props.account.description : "."}
            </Typography>
        </Button>
        <AccountListDialog queryInput={{
            filter: {
                type: {
                    eq: props.type,
                }
            }
        }} selected={props.account} onClick={onExpenseAccountSelect} onClose={onClose} open={dialogOpen} title={title} description={description}/>
    </div>;
}

export default AccountSelect;