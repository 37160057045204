// @flow

import {useAsyncQuery} from "../common/graphqlHooks";
import {Auth, graphqlOperation} from "aws-amplify";
import {Backdrop, CircularProgress, Divider, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {ErrorDialog} from "../common/ErrorDialog";
import {makeStyles} from "@material-ui/core/styles";
import {Fragment} from "react";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import TopBar from "../TopBar";

export const transactionByOwnerAndDateQuery = /* GraphQL */ `
  query TransactionByOwnerAndDate(
    $owner: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
  ) {
    TransactionByOwnerAndDate(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      limit: $limit
    ) {
      items {
        id
        date
        amount
        description
        owner
        debitAccount {
          name
          description
        }
        creditAccount {
          name
          description
        }
      }
      nextToken
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    root:{
        padding: theme.spacing(2),
    },
    itemContent:{
        display:"flex",
        flexGrow: 1,
        flexWrap: 'wrap',
    },
    itemRow:{
        flexGrow: 1,
        flexBasis: "100%",
        display:"flex",
        justifyContent:"space-between",
    },
    itemLeftCell:{
        display:"flex",
        flexBasis: "50%",
        justifyContent: 'flex-start',
        alignItems:"baseline",
    },
    itemRightCell:{
        display:"flex",
        flexBasis: "50%",
        justifyContent: 'flex-end',
        alignItems:"baseline",
    },
}));



function TransactionList(props) {
    const classes = useStyles();
    const history = useHistory();
    const limit = props.limit || 20;
    const [value, error, pending, reload] = useAsyncQuery(async (limit) => {
        const user = await Auth.currentAuthenticatedUser();
        return graphqlOperation(transactionByOwnerAndDateQuery, {
            owner: user.username,
            sortDirection: "DESC",
            limit: limit
        });
    }, limit);
    if (pending) {
        return <Backdrop open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    } else if (error) {
        return <ErrorDialog title="Error loading transactions" error={error} onClose={reload}/>
    }


    function renderTransaction(transaction) {
        const date = DateTime.fromISO(transaction.date).toLocal();

        function onClick() {
            history.push("/transactions/" + transaction.id)
        }

        return <Fragment>
            <ListItem key={transaction.id} onClick={onClick} button>
                <div className={classes.itemContent}>
                    <div className={classes.itemRow}>
                        <div className={classes.itemLeftCell}>
                            <Typography>{transaction.creditAccount.name}</Typography>
                        </div>
                        <div className={classes.itemRightCell}>
                            <Typography variant="body2" color="textSecondary">{date.toLocaleString(DateTime.DATETIME_SHORT)}</Typography>
                        </div>
                    </div>
                    <div className={classes.itemRow}>
                        <div className={classes.itemLeftCell}>
                            <Typography variant="body2" color="textSecondary">{transaction.debitAccount.name}</Typography>
                        </div>
                        <div className={classes.itemRightCell}/>
                    </div>
                    <div className={classes.itemRow}>
                        <div className={classes.itemLeftCell}>
                            <Typography variant="body2" color="textSecondary">{transaction.description}</Typography>
                        </div>
                        <div className={classes.itemRightCell}>
                            <Typography>${transaction.amount.toFixed(2)}</Typography>
                        </div>
                    </div>
                </div>
            </ListItem>
            <Divider />
        </Fragment>
    }

    const transactions = value.TransactionByOwnerAndDate.items;
    return <div>
        <TopBar title={"Transactions"}/>
        <List className={classes.root}>
            <ListItem component={Link} to={"/transactions/create"} key="new transaction" button>
                <ListItemText primary="Add new transaction..."/>
            </ListItem>
            <Divider />
            {transactions.map(t => renderTransaction(t))}
        </List>
    </div>

}

export default TransactionList;
