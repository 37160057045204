import {withAuthenticator} from '@aws-amplify/ui-react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Account from "./account/Account";
import AccountList from "./account/AccountList";
import TransactionDetail from "./transaction/TransactionDetail";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';
import TransactionList from "./transaction/TransactionList";
import SignOut from "./auth/SignOut";


function App() {
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path="/account/create/:groupId">
                            <Account/>
                        </Route>
                        <Route path="/expenseAccounts">
                            <AccountList type="EXPENSE" onClose={()=>null}/>
                        </Route>
                        <Route path="/assetAccounts">
                            <AccountList type="ASSET" onClose={()=>null}/>
                        </Route>
                        <Route path="/transactions/create">
                            <TransactionDetail />
                        </Route>
                        <Route path="/transactions/:transactionId">
                            <TransactionDetail />
                        </Route>
                        <Route path="/transactions">
                            <TransactionList />
                        </Route>
                        <Route path="/signout">
                            <SignOut />
                        </Route>
                        <Route path="/">
                            <TransactionList />
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </MuiPickersUtilsProvider>
    );
}

export default withAuthenticator(App);
